<template>
  <div class="probate-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE"></cny-banner>
    <header class="text_only">
      <h1>Trace Heirs</h1>
      <p>
        We help you locate potential heirs and supporting documents for probate cases leading back to Mainland China,
        Hong Kong, Macau, and Taiwan. We maintain ongoing partnerships with accredited members of the IAPPR and IR
        Global.
      </p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr toStep="email" label="Get a Quote"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <div class="image-caption story" id="probate_image">
        <img
          :data-src="$getAsset('/assets/services/probate/Speks-Adoption-Ad-half_Dongguan-Daily-Online1-2018-09-08.jpg')"
          class="lazyload with-shadow"
          alt="Official document"
        />
      </div>
      <h4>What We Can Do</h4>
      <ul>
        <li>Apply for documents at local government bureaus</li>
        <li>Search for potential heirs</li>
        <li>Search for household registration records or birth, marriage, and death certificates</li>
        <li>Facilitate notarization and certification of Chinese records</li>
      </ul>

      <h4>What We Need</h4>
      <ul>
        <li>Names of the decedent in Chinese characters</li>
        <li>Detailed hometown or residence address in China</li>
        <li>Any supporting files belonging to the decedent with Chinese text</li>
      </ul>

      <h4>What to Expect</h4>
      <ul>
        <li><strong>Duration:</strong> Cases typically last 1-4 months</li>
        <li><strong>Support:</strong> Dedicated MCR forensic genealogist</li>
        <li><strong>Progress:</strong> Email updates every month</li>
        <li><strong>Results:</strong> In-depth report of our findings</li>
        <li><strong>Pricing:</strong> US$160 per hour with optional cap*</li>
        <li class="asterik_note">* Application fees not included</li>
      </ul>
    </div>
    <prospect-request-end-actions>Get in touch to trace heirs today.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Probate Research',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'We locate potential heirs and supporting documents for probate cases leading back to Mainland China, Hong Kong, Macau, and Taiwan.',
      },
    ],
  },
  components: {
    xmasBanner,
    cnyBanner,
    ProspectRequestEndActions,
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
  },
};
</script>

<style scoped lang="scss">
header {
  > p {
    font-size: $text-size;
    text-align: left;
  }
}

#probate_image {
  margin-bottom: $paragraph-margin-size;
  max-width: $reading-max-width;
  &::v-deep img {
    height: 300px;
    object-position: bottom;
  }
}
</style>
