<template>
  <div class="travel-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE"></cny-banner>
    <header class="text_only">
      <h1>Visit Your Village</h1>
      <p>
        Embark on a journey back in time to the homeland of your ancestors. Walk in their footsteps, and discover your
        heritage for yourself.
      </p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr toStep="email" label="Get a Quote"></mcr-button-router-link-to-pr>
          <book-call-pr-button></book-call-pr-button>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <div @click="onVideoClick">
        <lazy-youtube-video src="https://www.youtube.com/embed/aIVwv_1HHhU" />
      </div>
      <h4>What to See</h4>
      <ul>
        <li>
          Family landmarks, such as the house your grandparents grew up in, the school they went to, the church they
          attended, or the harbor they left from
        </li>
        <li>Cultural landmarks, such as Sun Yat-Sen’s House or Overseas Chinese Museums</li>
        <li>Temples and graves</li>
        <li>Relatives and clan communities</li>
        <li>Family records</li>
      </ul>

      <h4>What We Can Do</h4>
      <div class="sub-title">Before Your Trip</div>
      <ul>
        <li>Design custom itineraries</li>
        <li>Conduct research on all available <router-link :to="{name: 'ancestry'}">ancestral traces</router-link></li>
        <li>Find and coordinate with distant relatives in China</li>
        <li>Organize trip logistics, including visa invitation letters, local accommodation, and transport</li>
        <li>Recommend tours in other parts of China</li>
      </ul>
      <div class="sub-title">During Your Trip</div>
      <ul>
        <li>
          Provide experienced, multilingual guides to give historical context and bridge any cultural or linguistic gaps
          between you and your newfound family
        </li>
        <li>Provide a professional film crew to document your trip</li>
      </ul>
      <div class="sub-title">After Your Trip</div>
      <ul>
        <li>
          Produce a <router-link :to="{name: 'film'}">film</router-link> or
          <router-link to="/report/demo/baisha" target="_blank">website</router-link> that preserves your journey and
          discoveries for generations to come
        </li>
        <li>Facilitate follow-up communication between you and your family in China</li>
      </ul>

      <div class="image-caption">
        <img
          :data-src="$getAsset('/assets/services/travel/travel2.jpg')"
          class="lazyload with-shadow"
          alt="People in front of the ancestral temple"
        />
      </div>

      <h4>How It Works</h4>
      <div class="sub-title">Share Your Travel Plans</div>
      <p>
        Tell us details about your ancestry, possible travel dates, and group size. We will get in touch to discuss the
        best options for your roots trip.
      </p>

      <div class="sub-title">Design Your Dream Heritage Tour</div>
      <p>
        Based on your interests and needs, we will work closely with you to map the perfect itinerary and make
        preparations for your arrival.
      </p>

      <div class="sub-title">Experience the Homecoming of a Lifetime</div>
      <p>
        Let the journey begin! We will be with you every step of the way as you explore your roots and add your own
        adventure to the pages of your family history.
      </p>

      <h4>Legacy Film<span class="new-badge">NEW!</span></h4>
      <p>
        Returning to your ancestral village in China? Bring along our professional film crew to capture your experience
        with a bespoke legacy film.
      </p>
      <router-link :to="{name: 'film'}">
        <p><strong>Learn more about legacy films</strong></p>

        <div class="image-caption">
          <img
            :data-src="$getAsset('/assets/services/film/film1.jpg')"
            class="lazyload with-shadow"
            alt="Videographer filming people pointing at an old document"
          />
        </div>
      </router-link>

      <h4>Pricing</h4>
      <p>Determined on a case-by-case basis.</p>
      <p>As a reference, daily rates for our guides range between US$500-700.</p>

      <div class="image-caption">
        <img
          :data-src="$getAsset('/assets/services/travel/travel3.jpg')"
          class="lazyload with-shadow"
          alt="People smiling holding a photo of their ancestor"
        />
      </div>
    </div>
    <prospect-request-end-actions>Plan a visit to your village today!</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

import BookCallPrButton from '@/base/elements/buttons/BookCallPrButton';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Travel Services',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Embark on a once-in-a-lifetime roots trip: visit your ancestral place and walk in the footsteps of your ancestors!',
      },
    ],
  },
  methods: {
    onVideoClick() {
      AnalyticsHandler.trackPlayVideoEvent('Travel page');
      AnalyticsMainHandler.trackPlayVideoEvent('Travel page', getRoutePageName(this.$route));
    },
  },
  components: {
    BookCallPrButton,
    ProspectRequestEndActions,
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
    LazyYoutubeVideo,
    xmasBanner,
    cnyBanner,
  },
};
</script>

<style scoped lang="scss">
.travel-page {
  .sub-title {
    font-weight: bold;
    margin-top: 32px;
  }
}
</style>
