<template>
  <div class="name-translation-page">
    <header class="text_only">
      <h1>Translate a Name</h1>
      <p>Translate your ancestor's romanized English name into Chinese characters.</p>
      <img :src="$getAsset('/assets/services/names/name-visualization.png')" width="706" height="122" />
      <div class="header_actions">
        <mcr-buttons-row>
          <stripe-price-checkout-session-button
            :price-id="stripePriceId"
            :success-path="stripeRedirectPath"
            :product-name="stripeProductName"
            :before-redirect="savePrEntryPoint"
          >
            Buy Translation &mdash; $65
          </stripe-price-checkout-session-button>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <h4>To find Chinese records, you'll need names in Chinese</h4>
      <p>Have an ancestor's name... but only in letters?</p>
      <p>The first step is to find the characters, since family history records in China are written in Chinese.</p>

      <p>
        The bad news&mdash; there can be <strong class="feature-highlight">multiple spellings for one name</strong>, and
        spellings can
        <strong class="feature-highlight">vary based on dialect, ancestral place, and the country of migration</strong>.
      </p>

      <p>The good news&mdash; our experts are here to help!</p>

      <div class="image-caption story" id="name-translation-image">
        <img
          :data-src="$getAsset('/assets/services/names/example-record.jpg')"
          class="lazyload with-shadow"
          alt="Immigration document"
          width="800"
          height="585"
        />
      </div>

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Price</span>
          <span class="price">US$ 50</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Report Received</span>
          <span class="price">&#60;2 Days</span>
        </div>
      </div>
      <div class="sub-title">First, we'll ask you for information that can help us narrow down the possibilities.</div>
      <p>
        This will include possible dialects spoken by your ancestor, where they came from in China, and where they
        migrated to abroad.
      </p>
      <p>If you don't know everything that's OK, our experts will work with what you have!</p>
      <div class="sub-title">You'll receive the results in a report containing:</div>
      <ul>
        <li>Possible characters for your ancestor's full name.</li>
        <li>The meaning of each character.</li>
        <li>A list of possible combinations that could make up your ancestor's Chinese name.</li>
        <li>Both simplified and traditional Chinese characters.</li>
        <!--<li>The dialects and places associated with the romanization of the name.</li>-->
        <li>Recommended next steps on how to continue your research.</li>
      </ul>

      <h4>Sample Deliverable</h4>
      <div class="example-report">
        <img
          :data-src="$getAsset('/assets/services/translation/Sample-name-translation-deliverable.png')"
          width="800"
          height="500"
          class="lazyload"
          alt=""
        />
      </div>

      <h5 class="centered">Unlock the key to your search today!</h5>
      <mcr-buttons-row>
        <stripe-price-checkout-session-button
          :price-id="stripePriceId"
          :success-path="stripeRedirectPath"
          :product-name="stripeProductName"
          :before-redirect="savePrEntryPoint"
        >
          Buy Translation &mdash; $65
        </stripe-price-checkout-session-button>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import StripePriceCheckoutSessionButton from '@/base/elements/payments/StripePriceCheckoutSessionButton';

import {FORM_TYPES} from '@/components/modules/contactUs/constants';

export default {
  metaInfo: {
    title: 'Translate a Name into Chinese',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "We translate your ancestor's English name into Chinese characters based on your family's Chinese dialect, traditional Chinese naming conventions and most common transcription practices in Chinese immigration documents.",
      },
    ],
  },
  computed: {
    stripeProductName() {
      return 'English to Chinese Name Translation';
    },
    stripePriceId() {
      return process.env.VUE_APP_STRIPE_TRANSLATION_NAME_PRODUCT_ID;
    },
    stripeRedirectPath() {
      let data = this.$router.resolve({
        name: 'get-in-touch',
        query: {type: FORM_TYPES.ancestortranslation, payment: 'success'},
      });
      return data.href;
    },
  },
  methods: {
    savePrEntryPoint() {
      this.$store.commit('saveProspectRequestEntryPointUrl', this.$route.fullPath);
    },
  },
  components: {
    StripePriceCheckoutSessionButton,
    mcrButtonsRow,
  },
};
</script>

<style scoped lang="scss">
@import '~@/components/common/styles/services.scss';

.example-report {
  border-bottom: 1px solid $divider-line-light;
  margin-bottom: 48px;
  padding: 12px 0;
}

.stripe-price-checkout-session-button::v-deep .mcr-button {
  min-width: 250px;
  min-height: 62px;
}
</style>
